import React from 'react'
import './torusVideo.css'
import imgThumb from '../../Assets/Why Torus/Video (1).png'
const TorusVideo = () => {
  return (
    <div className='torus-video'>
        <video controls>
            <source src='' type='' />
        </video>
    </div>
  )
}

export default TorusVideo