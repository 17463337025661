import React from 'react'
import './visionndMission.css'
// import rD from '../../Assets/careers/rd.png'
// import engineering from '../../Assets/careers/engineering.png'
// import fuelForFuture from '../../Assets/careers/fuel.png'
// import motor from '../../Assets/careers/motor.png'
// import marketing from '../../Assets/careers/marketing.png'
// import operation from '../../Assets/careers/operation.png'
const VisionndMission = () => {
    return (
        <div className='vision-mission-card'>
            <div className='vision-mission-header1'>
                {/* <h3></h3> */}
            </div>
            <div className='vision-mission-card-two'>
                <div className='vision-mission-cnt'>
                    <div className='vision-mission-header'>
                        <h3>Our Vision</h3>
                    </div>
                    <div className='vision-mission-para'>
                        Empowering the transition to a greener future through our visionary approach to motor
                        technology- Axial Flux to capitalize on its compactness and its high efficiency to leverage the vast
                        potential of axial flux motors to electrify diverse sectors such as industrial, irrigation, power
                        generation, robotics, HVAC and propel to sustainable growth on a global scale.
                    </div>
                </div>
                <div className='vision-mission-cnt'>
                    <div className='vision-mission-header'>
                        <h3>Our Mission</h3>
                    </div>
                    <div className='vision-mission-para'>
                        Empowering sustainable mobility through innovative axial flux motor and controller
                        solutions, driving the evolution of electric vehicles towards a cleaner future by making them go
                        faster, further and be affordable.                             </div>
                </div>
            </div>
        </div>
    )
}

export default VisionndMission