import React, { useEffect } from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import footerLogo from '../Assets/torus logo/footer.png'
import wa from '../Assets/footer/image1.png'
import li from '../Assets/footer/image2.png'
// import fb from '../Assets/footer/image3.png'
import insta from '../Assets/footer/image3.png'
import footerImage from '../Assets/footer/Group 92.png'
import { useNavigate } from 'react-router-dom'

const Footer = ({ blog, testimonial }) => {

    // console.log(testimonial)
    const nav = useNavigate()

    return (
        <>
            <div className='footer-display'>

                <div className='footer-main'>
                    <div className='footer-content'>
                        <picture className='footer-image'>
                            <img src={footerLogo} alt='' className='Footer-logo' />
                            {/* <img src={footerImage} alt='' className='' /> */}
                        </picture>
                        <div className='footer-menu'>
                            <div ><label className='footer-po' onClick={() => nav('/')}> Home </label></div>
                            <div> <label className='footer-po' onClick={() => nav('/product')}>Products</label></div>
                            <div><label className='footer-po' onClick={() => nav('/why-torus')}>Why Torus?</label></div>
                            <div><label className='footer-po' onClick={() => nav('/gallery')}>Gallery</label></div>
                            <div><label className='footer-po' onClick={() => nav('/careers')}>Careers</label></div>

                            {blog[0]?.showcase_value == 1 ? <div><label className='footer-po' onClick={() => nav('/blogs')}>Blogs</label></div> : ('')}

                            {testimonial[0]?.showcase_value == 1 ? <div><label className='footer-po' onClick={() => nav('/testimonials')}>Testimonials</label></div> : ("")}
                        </div>
                    </div>
                    <div className='footer-content-2'>
                        <div className='footer-inner'>
                            <div className='footer-inner-header'>
                                {/* <FontAwesomeIcon icon={faAddressCard} /> &nbsp;  */}
                                contact us</div>
                            <div className='footer-inside-div'>
                                No.28, 2nd Main Road, Kalaimagal Nagar,
                                Ekkatuthangal, Chennai, Tamil Nadu 600032
                            </div>
                        </div>
                        <div className='footer-inner'>
                            <div className='footer-inner-header'>
                                {/* <FontAwesomeIcon icon={faEnvelope} /> &nbsp;  */}
                                Email Address :</div>
                            <div className='footer-inside-div'>
                                info@torusrobotics.com
                            </div>
                        </div>
                        <div className='footer-inner'>
                            <div className='footer-inner-header'>
                                {/* <FontAwesomeIcon icon={faPhone} />
             &nbsp;  */}
                                Phone :</div>
                            <div className='footer-inside-div'>
                            +91 89395 65422
                            </div>
                        </div>
                        <div className='footer-social'>

                            <div><img src={wa} className='footer-icons' /></div>
                            <div><img src={insta} className='footer-icons' /></div>
                            <div><img src={li} className='footer-icons' /></div>
                            {/* <div><img src={fb} className='footer-icons' /></div> */}
                        </div>


                    </div>
                </div>
                <div className='cpy-right'>
                    Copyright © 2024 Torus Robotics &nbsp; | &nbsp; Powered by <a href='https://rakumuraitsolutions.com/' target='_blank' style={{ color: "white" }}>Rakumura IT solutions</a>
                </div>

            </div>

        </>
    )
}

export default Footer